import { ReactComponent as CheckBalanceLogo } from '../assests/check_balance.svg'
import { ReactComponent as RedeemVoucherLogo } from '../assests/redeem_voucher.svg'
import { Row, Col, Button, Container, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from '../components/footer/Footer.js';
import FooterCard from '../components/card/FooterCard.js';

import HomeCard from '../components/card/HomeCard.js';
import Card from 'react-bootstrap/Card';
import './pages.scss'
import Navbar from '../components/navbar/Navbar.js'

import Image1 from './../assests/image 1.jpg';
import Image2 from './../assests/image 2.jpg';
import Image3 from './../assests/image 3.jpg';
import Image4 from './../assests/image 4.jpg';
import Image5 from './../assests/image 5.jpg';




const home_cards = [
    {
        title: "Redeem Voucher Code",
        logo: RedeemVoucherLogo,
        description: "Redeem your Voucher Code into an eRupi in two simple steps.",
        button_text: "Redeem Voucher Code",
        link: "/redeem-voucher"
    },
    {
        title: "Check Voucher Balance",
        logo: CheckBalanceLogo,
        description: "Know the balance and validity of your voucher.",
        button_text: "Check Balance",
        link: "/"
    }
]


const footer_cards = [
    {
        title: "Got questions ?",
        description: "Check out our Help Center for User Guides and Frequently Asked Questions on how to use the voucher.",
        button_text: "Help Center",
        link: "/help"
    },
    {
        title: "Contact our sales team",
        description: "We are here to get you started or consult you on the solutions that best fit your business need.",
        button_text: <>email to <strong>sales@fincentive.co</strong></>,
        link: "/"
    }
]

function HomePage() {

    return <div className="dotted-background">
        <div className=''>
            <Navbar />
            <Row className='homepage-top' >
                <Col lg={4} className='banner-text'>


                    Welcome,
                    Please choose
                    what you want
                    to do!

                </Col>

                {home_cards.map(item => (
                    <Col> <HomeCard sm={12} title={item.title} description={item.description} Logo={item.logo} button_text={item.button_text} link={item.link} /></Col>
                ))}

            </Row>
        </div>


        <div className='homepage-bottom'>
            <Row className='partner-program-text '>Partner Programs</Row>
            <Row className='partner-program'>
                <Col><Link to="/"><Image src={Image1}></Image></Link></Col>
                <Col><Link to="/"><Image src={Image2}></Image></Link></Col>
                <Col><Link to="/"><Image src={Image3}></Image></Link></Col>
                <Col><Link to="/"><Image src={Image4}></Image></Link></Col>
                <Col><Link to="/"><Image src={Image5}></Image></Link></Col>
            </Row>
        </div>

        <Row className='card-background' >
            {footer_cards.map(item => (
                <Col > <FooterCard title={item.title} description={item.description} button_text={item.button_text} link={item.link} /></Col>
            ))}
        </Row>
        <Footer />

    </div>



}

export default HomePage;