import Accordion from 'react-bootstrap/Accordion';
import parse from 'html-react-parser'


function CustomAccordion({ items }) {
    return (
        <Accordion defaultActiveKey="0">
            {items ? items.map((item, index) => (

                <Accordion.Item key={index} eventKey={index.toString()} >
                    <Accordion.Header >{item.title}</Accordion.Header>
                    <Accordion.Body>
                        {parse(item.description)}
                    </Accordion.Body>
                </Accordion.Item>
            )) : null}
        </Accordion>
    );
}

export default CustomAccordion;