import { ReactComponent as Logo } from './../../assests/logo.svg';

import { Row, Col, Button, Container } from 'react-bootstrap'
import './navbar.scss'

function Navbar() {
    return <Row className=' saral-navbar'>
        <Col >
            <Logo className="logo"
                height="30px"
            />
        </Col>
    </Row>

}

export default Navbar