import React from 'react';
import { Toast } from 'react-bootstrap';

const ErrorPopup = ({ show, message, onClose }) => {
    return (
        <Toast
            onClose={onClose}
            show={show}
            delay={3000}
            autohide
            style={{
                position: 'fixed',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                minWidth: '200px',
                backgroundColor: '#f8d7da',
                color: '#721c24',
                border: '1px solid #f5c6cb',
                zIndex: 1050,
            }}
        >
            <Toast.Body style={{ textAlign: 'center' }}>{message}</Toast.Body>
        </Toast>
    );
};

export default ErrorPopup;
