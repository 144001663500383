import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import voucherReducer from './voucherReducer';
import logger from 'redux-logger'


const rootReducer = combineReducers({
    voucher: voucherReducer,
});

const store = createStore(rootReducer, applyMiddleware(logger, thunk));

export default store;