import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckBalanceLogo } from '../assests/check_balance.svg';
import { ReactComponent as RedeemVoucherLogo } from '../assests/redeem_voucher.svg';
import { Row, Col, Button, Container, Image, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/footer/Footer.js';
import FooterCard from '../components/card/FooterCard.js';
import RedemptionCard from '../components/card/RedemptionCard.js';
import Navbar from '../components/navbar/Navbar.js';
import Image1 from './../assests/image 1.jpg';
import Image2 from './../assests/image 2.jpg';
import Image3 from './../assests/image 3.jpg';
import Image4 from './../assests/image 4.jpg';
import Image5 from './../assests/image 5.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVoucher } from './../redux/voucherActions.js';
import ErrorModal from '../components/modal/ErrorModal.js';
import LoadingScreen from '../components/loadingScreen/loadingScreen.js';
import ErrorPopup from '../components/ErrorPopup/ErrorPopup.js'; // Import ErrorPopup

const loadingOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
};

const footer_cards = [
    {
        title: "Got questions ?",
        description: "Check out our Help Center for User Guides and Frequently Asked Questions on how to use the voucher.",
        button_text: "Help Center",
        link: "/help"
    },
    {
        title: "Contact our sales team",
        description: "We are here to get you started or consult you on the solutions that best fit your business need.",
        button_text: <>email to<strong>&nbsp; sales@fincentive.co</strong></>,
        link: "/"
    }
]





function HomePage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { voucher, loading, error, user_details } = useSelector((state) => state.voucher);
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    useEffect(() => {
        return () => {
            // Cleanup function to prevent running `setTimeout` after unmounting
            window.clearTimeout();
        };
    }, []);

    useEffect(() => {
        if (voucher) {
            navigate('/create-voucher');
        }
    }, [voucher, navigate]);

    useEffect(() => {
        if (error) {
            // Store the current state before reloading
            localStorage.setItem('showErrorPopup', 'true');
            window.location.reload();
        }
    }, [error]);

    useEffect(() => {
        const showPopup = localStorage.getItem('showErrorPopup') === 'true';
        if (showPopup) {
            setShowErrorPopup(true);
            localStorage.removeItem('showErrorPopup');
        }
    }, []);

    const handlePopupClose = () => {
        setShowErrorPopup(false);
    };

    // Handle the actual submission logic
    const handleSubmit = async (e, captchaToken) => {
        try {
            console.log("Handling form submission");
            await dispatch(fetchVoucher(e.target.code.value, e.target.pin.value, captchaToken));
            console.log("Voucher fetched successfully");
        } catch (error) {
            console.error("Error fetching voucher:", error);
        }
    };



    return <div className="dotted-background">
        {loading && (
            <div className="loading-overlay" style={loadingOverlayStyle}>
                <Spinner animation="border" variant="primary" />
            </div>
        )}
        <div className='landing'>
            <Navbar />
            <Row className='homepage-top' >
                <Col sm={12} md={6} className='banner-text'>


                    Welcome, <br></br>
                    Please enter the eGift voucher code & pin to redeem or check balance.

                </Col>

                {/* {home_cards.map(item => (
                    <Col> <HomeCard sm={12} title={item.title} description={item.description} Logo={item.logo} button_text={item.button_text} link={item.link} /></Col>
                ))} */}

                <Col sm={12} md={6}  >
                    <RedemptionCard handleSubmit={handleSubmit} />
                </Col>

            </Row>


            {/* Error Popup */}
            <ErrorPopup
                show={showErrorPopup}
                message="An error occurred"
                onClose={handlePopupClose}
            />
        </div>


        {/* <div className='homepage-bottom'>
            <Row className='partner-program-text '>Partner Programs</Row>
            <Row className='partner-program'>
                <Col><Link to="/"><Image src={Image1}></Image></Link></Col>
                <Col><Link to="/"><Image src={Image2}></Image></Link></Col>
                <Col><Link to="/"><Image src={Image3}></Image></Link></Col>
                <Col><Link to="/"><Image src={Image4}></Image></Link></Col>
                <Col><Link to="/"><Image src={Image5}></Image></Link></Col>
            </Row>
        </div> */}

        <Row className='card-background gap-sm' >
            {footer_cards.map((item, index) => (
                <Col md={12} lg={6} key={index}> <FooterCard title={item.title} description={item.description} button_text={item.button_text} link={item.link} /></Col>
            ))}
        </Row>
        <Footer />

    </div>



}

export default HomePage;
