import {
    FETCH_VOUCHER_REQUEST,
    FETCH_VOUCHER_SUCCESS,
    FETCH_VOUCHER_FAILURE,
    CREATE_VOUCHER_REQUEST,
    CREATE_VOUCHER_SUCCESS,
    CREATE_VOUCHER_FAILURE,
    CLEAR_STORE
} from './voucherActions';

const initialState = {
    loading: false,
    voucher: null,
    error: null,
    user_details: null
};

const voucherReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VOUCHER_REQUEST:
        case CREATE_VOUCHER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_VOUCHER_SUCCESS:
            return {
                ...state,
                loading: false,
                voucher: action.payload,
            };
        case CREATE_VOUCHER_SUCCESS:
            return {
                ...state,
                loading: false,
                user_details: action.payload,
            };
        case FETCH_VOUCHER_FAILURE:
        case CREATE_VOUCHER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case CLEAR_STORE:
            return initialState; // Reset the store to the initial state
        default:
            return state;
    }
};

export default voucherReducer;