import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage.js';
import HomePage2 from './pages/HomePage2.js';
import SuccessPage from './pages/SuccessPage.js';
import CreateVoucherPage from './pages/CreateVoucherPage.js';
import './App.scss';
import './components/card/card.scss'


function App() {


  return (
    <div>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage2 />} />
          <Route path="/create-voucher" element={<CreateVoucherPage />} />
          <Route path="/success" element={<SuccessPage />} />


        </Routes>
      </BrowserRouter>

    </div >

  );
}



export default App;