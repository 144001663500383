import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import SecondaryButton from '../button/SecondaryButton'
import './footer-card.scss'
import { Link } from 'react-router-dom';

function FooterCard({ title, description, link, button_text }) {
    return (
        <Card className='footer-card h-100'>
            <Card.Body>

                <Card.Title className='banner-text'>{title}</Card.Title>
                <Card.Text>
                    {description}
                </Card.Text>
            </Card.Body>

            <Card.Body className='card-btn'>
                <Link to={link}>
                    <SecondaryButton>{button_text}</SecondaryButton>
                </Link>

            </Card.Body>
        </Card>
    );

}

export default FooterCard
