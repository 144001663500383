
import Container from 'react-bootstrap/Container';

import { Row, Col, Button } from 'react-bootstrap'
import './footer.scss'


function Footer() {
    return <footer>
        <Container fluid className='footer-colored'>
            <Row className='flex-grow' />
            <Row className='footer-text bottom-row'>
                <Col>Terms of Use</Col>
                <Col>Privacy Policy</Col>
                <Col>© Fincentive Technologies Pvt Ltd. 2024</Col>
            </Row>
        </Container>
    </footer >
}




export default Footer