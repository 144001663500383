import { Button } from 'react-bootstrap'
import './button.scss'
import { ReactComponent as ArrowIcon } from '../../assests/Arrow 1.svg'


export default function PrimaryButton({ children }) {
    return <>
        <Button type="submit" variant="primary">{children}  <ArrowIcon /></Button>

    </>
}