import { ReactComponent as Logo } from './../../assests/logo.svg';

import { Row, Col, Button, Container, Image } from 'react-bootstrap'
import './general-navbar.scss'

function GeneralNavbar({ logo }) {
    return <Row className='saral-general-navbar'>
        <Col className='fixed' >
            <Logo className="logo"
                height="30px"
            />{logo ? <Image src={logo} /> : null}
        </Col>




    </Row>

}

export default GeneralNavbar